import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import OauthAuthorize from "coredde/lib/components/oauth/OauthAuthorize";
import ClientLogin from "coredde/lib/pages/client/ClientLogin/ClientLogin";
import WaitForContractSignature from "coredde/lib/pages/client/ClientLogin/components/contract/WaitForContractSignature";
import ForgottenPasswordPage from "coredde/lib/pages/client/ClientLogin/components/ForgottenPasswordPage";
import { Navigate, Route, Routes } from "react-router-dom";
import Cookies from "coredde/lib/pages/client/ClientLogin/Cookies";
import ContractProcessError from "coredde/lib/pages/client/ClientLogin/components/contract/ContractProcessError";
var InternetLoginRouterSK = function () {
    return (_jsx(_Fragment, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(Navigate, { to: "/indexSK.html" }) }), _jsx(Route, { path: "indexSK.html", element: _jsx(ClientLogin, {}) }), _jsx(Route, { path: "cookies", element: _jsx(Cookies, {}) }), _jsx(Route, { path: "forgottenPassword", element: _jsx(ForgottenPasswordPage, { isClient: true }) }), _jsx(Route, { path: "oauth/authorize", element: _jsx(OauthAuthorize, {}) }), _jsx(Route, { path: "contract/waitforsignatures", element: _jsx(WaitForContractSignature, {}) }), _jsx(Route, { path: "app/contract/contractprocesserror", element: _jsx(ContractProcessError, {}) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "/indexSK.html" }) })] }) }));
};
export default InternetLoginRouterSK;
